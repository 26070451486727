export enum FinancePermissionsEnum {
  AllocationsGet = 'FINANCE.RECEIPT.ALLOCATION.GET',
  ReversalsGet = 'FINANCE.RECEIPT.REVERSAL.GET',
  AllocationsSave = 'FINANCE.RECEIPT.ALLOCATION.SAVE',
  ReversalsSave = 'FINANCE.RECEIPT.REVERSAL.SAVE',
  ReversalsAuthorise = 'FINANCE.RECEIPT.REVERSAL.AUTHORISE',
  UnderwritingGet = 'FINANCE.RECEIPT.UNDERWRITING',
  ClaimsGet = 'FINANCE.RECEIPT.CLAIMS',
  GLAccountGet = 'FINANCE.RECEIPT.GLACCOUNT',
  PaymentGet = 'FINANCE.ENQUIRIES.PAYMENTS.GET',
  PaymentRelease = 'FINANCE.ENQUIRIES.PAYMENTS.RELEASE',
  GeneralLedgerReportGet = 'FINANCE.ENQUIRIES.GENERALLEDGER.GET',
  GetReinsuranceClaims = 'FINANCE.REINSURANCE_CLAIM.GET',
  GetEnquiryOnAccount = 'FINANCE.ENQUIRY_ON_ACCOUNT.GET',
  FinaliseReinsuranceClaims = 'FINANCE.REINSURANCE_CLAIM.FINALISE',
  GetRoadAccidentFundClaims = 'FINANCE.ROAD_ACCIDENT_FUND_CLAIM.GET',
  GetUnearnedPremiumReserve = 'FINANCE.UNEARNED_PREMIUM_RESERVE.GET',
  GetRoadAccidentFundFileNotes = 'FINANCE.ROAD_ACCIDENT_FUND_CLAIM.FILE_NOTES.GET',
  AddRoadAccidentFundFileNotes = 'FINANCE.ROAD_ACCIDENT_FUND_CLAIM.FILE_NOTES.ADD',
  AddRoadAccidentFundFileNotesByClaimOwner = 'FINANCE.ROAD_ACCIDENT_FUND_CLAIM.FILE_NOTES.ADD_CLAIM_OWNER',
  GetRoadAccidentFundClaim = 'FINANCE.ROAD_ACCIDENT_FUND_CLAIM.GET_SINGLE',
  GetRoadAccidentFundClaimDocuments = 'FINANCE.ROAD_ACCIDENT_FUND_CLAIM.DOCUMENTS.GET',
  GetAllPendingBankDetails = 'FINANCE.VERIFICATIONS.AUTHORISATION_REQUESTS',
  AddRoadAccidentFundClaimDocuments = 'FINANCE.ROAD_ACCIDENT_FUND_CLAIM.DOCUMENTS.ADD',
  RequestRoadAccidentFundAward = 'FINANCE.ROAD_ACCIDENT_FUND_CLAIM.AWARD.REQUEST',
  RequestArchive = 'FINANCE.ROAD_ACCIDENT_FUND_CLAIM.REQUEST_ARCHIVE',
  ReverseArchive = 'FINANCE.ROAD_ACCIDENT_FUND_CLAIM.REVERSE_ARCHIVE',
  MarkClaimAsSubmitted = 'FINANCE.ROAD_ACCIDENT_FUND_CLAIM.MARK_AS_SUBMITTED',
  AllocateAttorney = 'FINANCE.ROAD_ACCIDENT_FUND_CLAIM.ALLOCATE_ATTORNEY',
  SetReferenceNumber = 'FINANCE.ROAD_ACCIDENT_FUND_CLAIM.SET_REFERENCE_NUMBER',
  ConfirmPayment = 'FINANCE.ROAD_ACCIDENT_FUND_CLAIM.PAYMENT.CONFIRM',
  AddVerificationDocuments = 'FINANCE.VERIFICATIONS.DOCUMENTS.ADD',
}
